/* 04.03 Breadcrumb CSS */

.breadcrumb {
    padding: 40px 0;

    background-color: transparent;

    // Responsive
    @media #{$large-mobile}{
        padding: 25px 0;
    }

    & li {
        font-size: 16px;
        line-height: 1.5;

        display: flex;

        color: var(--clr-white);

        & + li {
            &::before {
                margin: 0 15px;
                font-size: 16px;
                content: "\f105";
                font-family: var(--fontAwesome5Pro);

                color: var(--clr-white);
                margin-top: 2px;
            }
        }

        & a {
            position: relative;

            display: block;

            color: var(--clr-white);

            &::before {
                position: absolute;
                right: 0;
                bottom: 0;

                width: 0;
                height: 1px;

                content: "";
                transition: var(--transition);

                background-color: var(--clr-primary);
            }

            &:hover {
                color: var(--clr-primary);

                &::before {
                    left: 0;

                    width: 100%;
                }
            }
        }
    }
}