/* 04.05 CTA CSS */



.icon-up-down-animation{
    position: relative;
    z-index: 9;

    & .shape {
        position: absolute;
        display: inline-block;
        width: 96px;
        height: 96px;
        z-index: -1;
        img{
            box-shadow: 0 0 40px 0 rgba(51,51,51,0.1);
            border-radius: 35px;
        }

        &-1 {
            top: -170px;
            left: 300px;
            animation: up-down infinite 4s ease-in-out;
        }
        &-2 {
            top: -150px;
            right: 400px;
            animation: up-down infinite 5s ease-in-out;
        }
        &-3 {
            top: -55px;
            left: 15px;
            animation: up-down infinite 6s ease-in-out;
            
        }
        &-4 {
            top: -55px;
            right: 15px;
            width: 130px;
            height: 130px;
            animation: up-down infinite 4s ease-in-out;
            img{
                border-radius: 45px;
            }
        }
        &-5 {
            bottom: -80px;
            left: 80px;
            width: 130px;
            height: 130px;
            animation: up-down infinite 5s ease-in-out;
            img{
                border-radius: 45px;
            }
        }
        &-6 {
            bottom: -30px;
            right: 300px;
            animation: up-down infinite 6s ease-in-out;
        }
        &-7 {
            bottom: -40px;
            left: 300px;
            animation: up-down infinite 4s ease-in-out;
        }
        &-8 {
            bottom: -155px;
            right: 80px;
            animation: up-down infinite 5s ease-in-out;
        }
        &-9 {
            bottom: 0;
            right: 15px;
            animation: up-down infinite 6s ease-in-out;
        }
    }

    // Responsive
    @media #{$desktop-device}{
        & .shape {
            &-1 {
                top: -170px;
                left: 300px;
            }
            &-2 {
                top: -150px;
                right: 150px;
            }
            &-3 {
                top: -55px;
                left: 15px;
                
            }
            &-4 {
                top: -55px;
                right: 0px;
                width: 100px;
                height: 100px;
                img{
                    border-radius: 35px;
                }
            }
            &-5 {
                bottom: -80px;
                left: 20px;
                width: 100px;
                height: 100px;
                img{
                    border-radius: 35px;
                }
            }
            &-6 {
                bottom: -30px;
                right: 230px;
            }
            &-7 {
                bottom: -40px;
                left: 180px;
            }
            &-8 {
                bottom: -125px;
                right: 60px;
            }
            &-9 {
                bottom: 0;
                right: 15px;
            }
        }
    }
    @media #{$tablet-device}{
        & .shape {
            width: 60px;
            height: 60px;
            img{
                border-radius: 20px;
            }
            &-1 {
                top: -115px;
                left: 150px;
            }
            &-2 {
                top: -115px;
                right: 150px;
            }
            &-3 {
                top: -80px;
                left: 15px;
                
            }
            &-4 {
                top: -100px;
                right: 0px;
                width: 90px;
                height: 90px;
                img{
                    border-radius: 35px;
                }
            }
            &-5 {
                bottom: 0;
                left: 10px;
                width: 90px;
                height: 90px;
                img{
                    border-radius: 35px;
                }
            }
            &-6 {
                bottom: 30px;
                right: 150px;
            }
            &-7 {
                bottom: 40px;
                left: 160px;
            }
            &-8 {
                bottom: -70px;
                right: 70px;
            }
            &-9 {
                bottom: 10px;
                right: 15px;
            }
        }
    }

    @media #{$large-mobile}{
        & .shape {
            width: 50px;
            height: 50px;
            img{
                border-radius: 15px;
            }
            &-1 {
                top: -115px;
                left: 150px;
            }
            &-2 {
                top: -115px;
                right: 150px;
            }
            &-3 {
                top: -80px;
                left: 15px;
                
            }
            &-4 {
                top: -100px;
                right: 0px;
                width: 60px;
                height: 60px;
                img{
                    border-radius: 20px;
                }
            }
            &-5 {
                bottom: 0;
                left: 10px;
                width: 60px;
                height: 60px;
                img{
                    border-radius: 20px;
                }
            }
            &-6 {
                bottom: 0px;
                right: 120px;
            }
            &-7 {
                bottom: 10px;
                left: 100px;
            }
            &-8 {
                bottom: -70px;
                right: 45px;
            }
            &-9 {
                bottom: 10px;
                right: 15px;
            }
        }
    }

    @media #{$small-mobile}{
        & .shape {
            &-6 {
                bottom: 0px;
                right: 90px;
            }
        }
    }

    @media #{$large-mobile}{
        & .shape {
            &-1 {
                top: -110px;
                left: 90px;
            }
            &-2 {
                top: -110px;
                right: 90px;
            }
            &-3 {
                top: -80px;
                left: 15px;
                
            }
            &-4 {
                top: -100px;
                right: 10px;
            }
            &-5 {
                bottom: 0;
                left: 10px;
                width: 60px;
                height: 60px;
                img{
                    border-radius: 20px;
                }
            }
            &-6 {
                display: none;
            }
            &-7 {
                display: none;
            }
            &-8 {
                bottom: -70px;
                right: 45px;
            }
            &-9 {
                bottom: 10px;
                right: 15px;
            }
        }
    }
}

/* Up Down Animation */
.up-down { 
    animation: up-down infinite 2s ease-in-out 
}
@keyframes up-down {
    50% { transform: translateY(30px) }
}