/* 04.02 Brand CSS */

.brand-list{
    transition: var(--transition);
    &:hover{
        .brand{
            a{
                display: block;
                img{
                    opacity: 0.5;
                }
            }
        }
    }
    .brand{
        display: flex;
        justify-content: center;
        align-self: center;
        a{
            display: block;
            img{
                opacity: 1;
            }
        }
        &:hover{
            a{
                img{
                    opacity: 1;
                }
            }
        }
    }
}
